<template>
    <div class="region-input-item">
        <div class="region-input-item__parent">
            <div class="region-input-item__expand">
                <a href="" class="region-input-item__expander" @click.prevent="expand()">
                    <span v-if="children.length">
                        <span v-if="isExpanded">
                            -
                        </span>
                        <span v-else>
                            +
                        </span>
                    </span>
                </a>
            </div>
            <div class="region-input-item__input">
                <input type="checkbox" :checked="isChecked" class="region-input-item__checkbox" :id="htmlId" @change="change">
                <label :for="htmlId">
                    {{ name }}
                </label>
            </div>
        </div>
        <div class="region-input-item__children" v-if="isExpanded">
            <region-item v-for="group in children" :id="group.id" :name="group.name" :children="group.children" :parent-id="id"></region-item>
        </div>
    </div>
</template>

<script>
  export default {
    name: "RegionItem",
    inject: {
      input: {},
      parent: { default: null }
    },
    provide() {
      return {
        parent: this
      }
    },
    props: {
      id: Number,
      parentId: {
        type: Number,
        required: false,
        default: null
      },
      name: String,
      children: {
        type: Array
      },
      expanded: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isExpanded: this.expanded
      }
    },
    computed: {
      htmlId() {
        return 'region-' + this.id;
      },
      isChecked() {
        return (!this.input.val.includes(-this.id)) && (this.input.val.includes(this.id) || this.isParentChecked);
      },
      isParentChecked() {
        return this.parent ? this.parent.isChecked : false;
      }
    },
    methods: {
      expand() {
        this.isExpanded = !this.isExpanded;
      },
      disable() {
        this.input.val = this.input.val.filter((item) => {
            return (item !== this.id) && (item !== -this.id);
        });
        if (this.isParentChecked) {
          this.input.val.push(-this.id);
        }
        this.$children.forEach(($child) => {
          $child.$emit('parentDisabled');
        })
      },
      enable() {
        if (this.input.val.includes(-this.id)) {
          this.input.val = this.input.val.filter((item) => {
            return (item !== -this.id);
          })
        } else {
          this.input.val.push(this.id);
        }
      },
      parentDisabled() {
        this.disable();
      },
      change(e) {
        if (e.target.checked) {
          this.enable();
        } else {
          this.disable();
        }
      }
    },
    mounted() {
      this.$on('parentDisabled', this.parentDisabled);
    }
  }
</script>

<style scoped lang="scss">
    .region-input-item {
        &__parent {
            display: flex;
            align-items: center;
        }

        &__expand {
            width: 20px;
            text-align: center;
        }

        &__expander {
            text-decoration: none;
            font-size: 18px;
        }

        &__input {
            display: flex;
            align-items: center;

            input {
                margin: 0;
            }

            label {
                padding-left: 5px;
                padding-top: 2px;
                margin: 0;
            }
        }

        &__children {
            padding-left: 10px;
        }
    }
</style>