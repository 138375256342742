<template>
    <header class="header">
        <h1 class="header__title">
            <slot name="title"></slot>
        </h1>
    </header>
</template>

<script>
  export default {
    name: "PageHeader",
    props: {
      title: {
        type: Object
      }
    }
  }
</script>

<style lang="scss">
    .header {
        margin-bottom: 30px;

        &__title {
            font-size: 20px;
        }
    }
</style>