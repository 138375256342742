<template>
    <div class="system-status">
        <div class="system-status__item" v-for="(item, key) in statuses" :key="key">
            <span class="system-status__name">
                {{ item.title }} -
            </span>
            <span class="system-status__value">
                {{ item.value }}
            </span>
        </div>
    </div>
</template>

<script>
  export default {
    name: "SystemStatus",
    data() {
      return {
        timeoutId: null
      };
    },
    created() {
      this.fetch();
    },
    beforeDestroy() {
      clearTimeout(this.timeoutId);
    },
    methods: {
      fetch() {
        this.$store.dispatch('common/fetchStatus').then(() => {
          if (!this._isDestroyed){
            this.timeoutId = setTimeout(() => {
              this.fetch();
            }, 5000);
          }
        })
      },
    },
    computed: {
      status() {
        return this.$store.getters['common/status'];
      },
      statuses() {
        let statuses = [];
        if (this.status) {
          statuses = [
            {title: "Активных токенов", value: this.status.enabledTokens},
            {title: "Отключенных токенов", value: this.status.disabledTokens},
            {title: "Использовано фраз", value: this.status.dailyUsagePhrases},
            {title: "Доступно для подбора фраз", value: this.status.dailyUsageLeft},
            {title: "Запросов активно", value: this.status.activeReports},
            {title: "Запросов в очереди", value: this.status.pendingReports},
          ];
        }
        return statuses;
      }
    }
  }
</script>

<style scoped lang="scss">
    .system-status {
        padding-top: 30px;
    }
</style>