<template>
    <tr class="task-list-item">
        <td class="task-list-item__name task-list-table__column task-list-table__column_name">
            <router-link :to="{name: 'task:view', params: {id: task.id}}">
                {{ task.name ? task.name : 'Без имени' }}
            </router-link>
        </td>
        <td class="task-list-item__status task-list-table__column task-list-table__column_status">
            {{ status }}
        </td>
        <td class="task-list-item__metrics task-list-table__column task-list-table__column_metrics">
            <task-requests-statuses :metrics="task.statuses"></task-requests-statuses>
        </td>
        <td class="task-list-item__actions task-list-table__column task-list-table__column_actions">
            <a target="_blank" v-if="task.reportId" :href="downloadLink">
                Cкачать отчет
            </a>
        </td>
    </tr>
</template>

<script>
  import TaskRequestsStatuses from './TaskRequestsStatuses.vue';
  import Api from '../store/api/index';

  export default {
    name: "TaskListItem",
    components: {
      TaskRequestsStatuses
    },
    props: {
      task: {
        type: Object
      }
    },
    computed: {
      downloadLink(){
        return Api.root + `/report/download?id=${this.task.reportId}`;
      },
      status() {
        return this.task.reportId ? "Готов" : "В работе";
      }
    }
  }
</script>

<style lang="scss">
    .task-list-item{
        line-height: 25px;
        
        &__actions{
            button{
                margin-left: 20px;
            }
         }
    }
</style>