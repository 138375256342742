<template>
    <div class="request-metrics">
        <div class="request-metrics__list">
            <div class="request-metrics__item" v-for="(value, key) in metrics" :title="verboseMetric(key)" :key="key">
                <div class="request-metrics__visual">
                    <div :class="['request-metrics__lamp', 'request-metrics__lamp_' + key ]">
                        <span>
                            {{ value }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: "TaskRequestsStatuses",
    created() {

    },
    props: {
      metrics: {
        type: Object
      },
      id:{
        type: Number
      }
    },
    computed: {
      ...mapGetters({
        verboseMetric: 'tasks/verboseMetric',
      })
    }
  }
</script>

<style lang="scss">
    .request-metrics {
    }

    .request-metrics__list {
        display: flex;
        align-items: center;
    }

    .request-metrics__item {
        display: flex;
        align-items: center;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    .request-metrics__lamp {
        color: #fff;
        padding: 2px 7px;
        border-radius: 100px;
        font-size: 12px;


        &_new {
            background-color: #55b;
        }

        &_pending {
            background-color: #000;
        }

        &_active {
            background-color: #cc5;
        }

        &_error {
            background-color: #b55;

            a{
                color: #fff;
            }
        }

        &_done {
            background-color: #5b5;
        }
    }
</style>