import './scss/app.scss';
import App from './vue/App.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes.js';
import store from './store/index.js';

Vue.use(VueRouter);

const router = new VueRouter({
  routes
});

const app = new Vue({
  el: "#app",
  router: router,
  store: store,
  render: h => h(App)
});