<template lang="html">
    <div class="app">
        <header class="app__header">
            <div class="app__info">
                <div class="app__info-text-large" aria-hidden="true">
                    Коллектор. Большой и сильный.
                </div>
                <div class="app__info-text-default">
                    Коллектор. Большой и сильный.
                </div>
            </div>
            <nav class="app__nav">
                <ul class="app__menu">
                    <li class="app__menu-item">
                        <router-link :to="{name: 'task:list'}" class="app__menu-link">Список задач</router-link>
                    </li>
                    <li class="app__menu-item">
                        <router-link :to="{name: 'task:create'}" class="app__menu-link">Создать задачу</router-link>
                    </li>
                </ul>
            </nav>
        </header>
        <!-- отображаем тут компонент, для которого совпадает маршрут -->
        <main>
            <router-view></router-view>
        </main>

        <system-status></system-status>
    </div>
</template>

<script>
  import SystemStatus from './SystemStatus.vue';

  export default {
    components: {
      SystemStatus
    }
  }
</script>

<style lang="scss">
    .app {
        margin: 0 auto;
        max-width: 1200px;
        padding-bottom: 50px;

        &__header {
            margin-bottom: 30px;
        }

        &__menu {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        &__menu-item {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }

        &__menu-link {
            text-decoration: none;
        }

        &__info {
            text-align: center;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 160px;
        }

        &__info-text-large {
            position: absolute;
            font-size: 80px;
            color: #f8f8f8;
            left: -500px;
            top: -500px;
            bottom: -500px;
            right: -500px;
            display: flex;
            align-items: center;
            justify-content: center;
            user-focus: none;
            user-select: none;
            z-index: 1;
        }

        &__info-text-default {
            font-size: 40px;
            color: #000;
            position: relative;
            z-index: 10;
        }
    }
</style>