import api from "../api";
import _ from "lodash";

const regionsModule = {
  namespaced: true,
  state: {
    regions: []
  },
  getters: {
    regions(store) {
      return store.regions;
    },
    tree(store) {
      let treeList = [];
      let lookup = {};

      const idAttr = 'id';
      const parentAttr = 'parentId';
      const childrenAttr = 'children';

      store.regions.forEach(function(obj) {
        lookup[obj[idAttr]] = obj;
        obj[childrenAttr] = [];
      });
      store.regions.forEach(function(obj) {
        if (obj[parentAttr] != null) {
          lookup[obj[parentAttr]][childrenAttr].push(obj);
        } else {
          treeList.push(obj);
        }
      });

      return treeList
    }
  },
  mutations: {
    setRegions(state, regions) {
      state.regions = regions;
    },
  },
  actions: {
    fetchRegions({commit, state}) {
      if (_.isEmpty(state.regions)) {
        api.request({
          url: '/regions'
        }).then(({data}) => {
          if (data.hasOwnProperty('data') && data.data) {
            commit('setRegions', data.data);
          }
        })
      }
    }
  }
};

export default regionsModule;