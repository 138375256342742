<template>
    <div class="task-page" v-if="!this.loading">
        <div class="task-page__header">
            <page-header>
                <template slot="title">
                    Просмотр задачи "{{ task.info.name }}"
                </template>
            </page-header>
        </div>

        <div class="task-page__statuses">
            <task-requests-statuses :metrics="task.info.statuses"></task-requests-statuses>
        </div>

        <div class="task-page__status">
            {{ status }}

            <a target="_blank" :href="downloadLink" class="task-page__download" v-if="task.info.reportId">
                Cкачать отчет
            </a>
        </div>

        <div class="task-page__errors">
            <div class="task-page__error" v-for="(error, key) in task.errors" :key="key">
                {{ error.text }}
            </div>
        </div>

    </div>
</template>

<script>
  import TaskRequestsStatuses from './TaskRequestsStatuses.vue';
  import PageHeader from './PageHeader.vue';
  import Api from '../store/api/index';
  
  import _ from 'lodash';

  export default {
    name: "Task",
    data() {
      return {
        id: this.$route.params.id,
        task: {},
        loading: true,
      }
    },
    components: {
      PageHeader,
      TaskRequestsStatuses
    },
    methods: {
      fetch() {
        this.loading = true;
        this.$store.dispatch('tasks/fetchTask', this.id).then(data => {
          this.$set(this, 'task', data);
          this.loading = false;
        }).catch(errors => {
          console.log(errors);
          this.loading = false;
        })
      },
      downloadLink(){
        return Api.root + `/report/download?id=${this.task.info.reportId}`;
      },
    },
    computed: {
        status() {
          return this.task.info.reportId ? "Готов" : "В работе";
        }
    },
    mounted() {
      this.fetch();
    }
  }
</script>

<style lang="scss">
    .task-page {
        &__statuses {
            margin-bottom: 20px;
        }

        &__status {
            margin-bottom: 40px;
        }

        &__error {
            border-radius: 2px;
            background-color: #ffe2dd;
            color: #6e1114;
            font-size: 14px;
            padding: 10px 20px;
            margin-bottom: 10px;
        }
    }
</style>