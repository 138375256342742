<template>
    <div class="task-create">
        <div class="task-create__header">
            <page-header>
                <template slot="title">
                    Создать задачу
                </template>
            </page-header>
        </div>
        <form action="" @submit.prevent="submit" class="task-create__form">
            <div class="task-create__loading" v-if="pushing">
                <div class="task-create__loading-text">
                    Создаем задачу, подождите
                </div>
            </div>

            <div class="task-create__line">
                <div class="task-create__input task-create__input_full">
                    <label for="task_name">
                        Наименование задачи
                    </label>
                    <input name="" id="task_name" v-model="task.name"/>
                </div>
            </div>

            <div class="task-create__line">

            </div>

            <div class="task-create__line">
                <div class="task-create__input task-create__input_regions">
                    <label>
                        Регионы
                    </label>

                    <region-input v-model="task.regions" name="regions" :tree="this.$store.getters['regions/tree']"></region-input>
                </div>

                <div class="task-create__input task-create__input_phrases">
                    <label for="task_phrases">
                        Фразы
                    </label>
                    <textarea name="" id="task_phrases" cols="30" rows="10" v-model="task.phrases"></textarea>
                </div>

                <div class="task-create__input task-create__input_minus">
                    <label for="task_minus">
                        Минус-слова
                    </label>
                    <textarea name="" id="task_minus" cols="30" rows="10" v-model="task.minus"></textarea>
                </div>
            </div>

            <div class="task-create__errors" v-if="hasErrors">
                <ul class="task-create__errors-list">
                    <li class="task-create__error" v-for="(error, key) in errors" :key="key">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <div class="task-create__actions">
                <button type="submit" class="button">
                    Создать
                </button>
            </div>
        </form>
    </div>
</template>

<script>
  import PageHeader from './PageHeader.vue';
  import RegionInput from './Helpers/RegionInput/RegionInput.vue';
  
  import _ from 'lodash';

  export default {
    name: "TaskCreate",
    data() {
      return {
        errors: [],
        task: {
          name: "",
          minus: "",
          phrases: "",
          regions: [225]
        },
        pushing: false,
      }
    },
    beforeCreate() {
      this.$store.dispatch('regions/fetchRegions');
    },
    components: {
      PageHeader,
      RegionInput
    },
    methods: {
      submit() {
        this.pushing = true;
        this.$store.dispatch('tasks/createItem', {
          name: this.resultName,
          targeting: this.resultRegions,
          phrases: this.resultPhrases,
          minus: this.resultMinus
        }).then(data => {
          this.errors = [];
          this.pushing = false;
          this.$router.push({name: 'task:list'});
        }).catch(errors => {
          this.errors = errors;
          this.pushing = false;
        })
      }
    },
    computed: {
      hasErrors() {
        return !_.isEmpty(this.errors);
      },
      resultPhrases() {
        return this.task.phrases.split(/\r|\n/).filter(s => s.trim().length > 0);
      },
      resultMinus() {
        return this.task.minus.split(/\r|\n|,/).map(function(phrase){
          phrase = phrase.trim();
          phrase = phrase.replace(/[\+\-]/,'');
          return /\s/.test(phrase) ? '(' + phrase + ')' : phrase;
        }).filter(s => s.trim().length > 0);
      },
      resultRegions() {
        return this.task.regions;
      },
      resultName() {
        return this.task.name;
      },
      regions() {
        return this.$store.getters['regions/regions'];
      }
    },
    watch: {
      'task.minus': {
        handler() {
          console.log(this.resultMinus);
        }
      }
    }
  }
</script>

<style lang="scss">
    .task-create {
        &__line {
            display: flex;
            margin: 0 -10px 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__input {
            padding: 0 10px;

            &_phrases {
                flex: 1 1 auto;
            }

            &_regions {
                flex: 0 0 300px;
            }

            &_minus {
                flex: 0 0 300px;
            }
        }


        &__errors {
            margin-bottom: 10px;
            padding-top: 10px;

        }

        &__form {
            position: relative;
        }

        &__legend {
            margin-bottom: 15px;
        }

        &__fieldset {
            margin-bottom: 30px;
        }

        &__loading {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background-color: rgba(255, 255, 255, 0.9);
            font-size: 20px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }
</style>