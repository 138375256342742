import axios from "axios";

class Api {
  constructor(options) {
    let opts = options || {};
    this.root = opts.root || '/api';
  }

  request(config) {
    let options = config;
    options.baseURL = this.root;
    options.params = options.params || {};
    return axios(options);
  }
}

const api = new Api({
  root: `${ENV.API_ROOT}`
});
export default api;