import api from "../api";
import _ from "lodash";

const commonModule = {
  namespaced: true,
  state: {
    status: {}
  },
  getters: {
    status(store) {
      return store.status;
    }
  },
  mutations: {
    setStatus(state, status) {
      state.status = status;
    },
  },
  actions: {
    fetchStatus({commit, state}) {
      if (_.isEmpty(state.regions)) {
        api.request({
          url: '/common/status'
        }).then(({data}) => {
          if (data) {
            commit('setStatus', data);
          }
        })
      }
    }
  }
};

export default commonModule;