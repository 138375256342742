import api from "../api";
import {verboseList} from "../helpers";
import _ from "lodash";

const tasksModule = {
  namespaced: true,
  state: {
    tasks: [],
    request: {},
    requestStatuses: [
      {key: 'new', value: 'Новые'},
      {key: 'active', value: 'В работе'},
      {key: 'error', value: 'Ошибка'},
      {key: 'done', value: 'Завершен'}
    ]
  },
  getters: {
    list(store) {
      return store.tasks;
    },
    errorPhrase(store){
      return store.errorPhrase
    },
    item(store) {
      return store.request;
    },
    verboseMetric: store => val => {
      return verboseList(store.requestStatuses, val);
    }
  },
  mutations: {
    setList(state, tasks) {
      state.tasks = tasks;
    }
  },
  actions: {
    fetchList({commit}) {
      return api.request({
        url: '/tasks'
      }).then(({data}) => {
        commit('setList', data.data)
      }).catch(() => {

      })
    },
    fetchTask({commit}, id) {
      return new Promise((resolve, reject) => {
        api.request({
          url: `/task/${id}`,
        }).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        })
      })
    },
    removeItem({commit}, options) {
      return api.request({
        method: 'delete',
        url: '/request',
        data: options
      })
    },
    createItem({commit}, data) {
      return new Promise((resolve, reject) => {
        api.request({
          url: '/task/create',
          method: 'post',
          data: JSON.stringify(data)
        }).then((response) => {
          console.log(response);
          if (response.hasOwnProperty('data')) {
            if (response.data.hasOwnProperty('Error')) {
              reject([
                response.data.Error
              ])
            }
            resolve(response.data);
          }
        }).catch((e, data) => {
          console.log(e.response.data);
          let message = "Непредвиденная ошибка сохранения данных.";
          if (e.response && e.response.data && e.response.data.message) {
            message = e.response.data.message;
          }
          reject([
            message
          ]);
        })
      });
    },
  }
};

export default tasksModule;