<template lang="html">
    <div class="task-list">
        <div class="task-list__header">
            <page-header>
                <template slot="title">
                    Список задач
                </template>
            </page-header>
        </div>
        <table class="task-list__items task-list-table">
            <thead class="task-list-table__head">
                <tr>
                    <td class="task-list-table__column task-list-table__column_name">
                        Наименование
                    </td>
                    <td class="task-list-table__column task-list-table__column_status">
                        Статус
                    </td>
                    <td class="task-list-table__column task-list-table__column_metrics">
                        Метрики
                    </td>
                    <td class="task-list-table__column task-list-table__column_actions">
                        Действия
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr is="task-list-item" v-for="item in tasks" :key="item.id" :task="item"></tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import TaskListItem from './TaskListItem.vue';
import PageHeader from './PageHeader.vue';

export default {
  components: {
    TaskListItem,
    PageHeader
  },
  data() {
    return {
      timeoutId: null
    };
  },
  created() {
    this.fetchList();
  },
  beforeDestroy() {
    clearTimeout(this.timeoutId);
  },
  methods: {
    fetchList() {
      this.$store.dispatch('tasks/fetchList').then(() => {
        if (!this._isDestroyed){
          this.timeoutId = setTimeout(() => {
            this.fetchList();
          }, 5000);
        }
      })
    },
  },
  computed: {
    tasks() {
      return this.$store.getters['tasks/list'];
    }
  }
}
</script>

<style lang="scss">
    .task-list-table {
        width: 100%;

        &__column {
            padding: 10px;
            border-bottom: 1px solid #dadada;
        }
    }
</style>