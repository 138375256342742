<template>
    <div class="region-input">
        <region-item v-for="group in tree" :id="group.id" :name="group.name" :children="group.children"></region-item>
    </div>
</template>

<script>
  import RegionItem from './RegionItem.vue';

  export default {
    name: "RegionInput",
    components: {
      RegionItem
    },
    provide() {
      return {
        input: this
      }
    },
    props: {
      name: {
        type: String
      },
      value: {
        type: Array
      },
      tree: {
        type: Array
      }
    },
    data() {
      return {
        val: this.value
      }
    },
    watch: {
      val() {
        this.$emit('input', this.val)
      }
    }
  }
</script>

<style scoped lang="scss">

</style>