import Vue from 'vue';
import Vuex from 'vuex';

import tasksModule from "./modules/tasks";
import regionsModule from "./modules/regions";
import commonModule from "./modules/common";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    tasks: tasksModule,
    regions: regionsModule,
    common: commonModule
  }
});

export default store;