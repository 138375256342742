import { render, staticRenderFns } from "./Task.vue?vue&type=template&id=2f3c5db4&"
import script from "./Task.vue?vue&type=script&lang=js&"
export * from "./Task.vue?vue&type=script&lang=js&"
import style0 from "./Task.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "Task.vue"
export default component.exports